import classNames from "classnames";
import styles from "./index.module.scss";

type sizeType = "size48" | "size40" | "size44" | "size36" | "size32";
type variantType =
  | "primaryBtn"
  | "primaryOutlineBtn"
  | "blackBtn"
  | "whiteBtn"
  | "grayBtn"
  | "whiteOpacityBtn"
  | "primaryTextBtn"
  | "whiteBoxShadowBtn";

const LoadingIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M11 3C11 2.44772 11.4477 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 11.4477 2.44772 11 3 11C3.55228 11 4 11.4477 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C11.4477 4 11 3.55228 11 3Z'
        fill='currentColor'
      />
    </svg>
  );
};

const Loading = () => {
  return (
    <span className={styles.loadingWrapper}>
      <LoadingIcon />
    </span>
  );
};

export const ButtonWeb3 = (
  props: React.HTMLProps<HTMLDivElement> & {
    btnSize?: sizeType;
    loading?: boolean;
    variant?: variantType;
  }
) => {
  const {
    btnSize = "size36",
    className,
    variant = "primaryBtn",
    disabled,
    loading,
    children,
    ...rest
  } = props;

  return (
    <div
      className={classNames(
        styles.pcCommButton,
        styles[btnSize],
        styles[variant],
        loading && styles.loadingBtn,
        disabled && styles.disabled,
        className
      )}
      {...rest}
    >
      {loading ? <Loading /> : null}
      {children}
    </div>
  );
};
