// 过滤格式，将格式生成成符合集联选择器的格式
export function generatePairs(original, options) {
  const result = [];
  let oneLabel = "";

  original.category_value.forEach((parent) => {
    const parentObj = options.find((r) => r.value === parent);
    const hasOnlyOneChild = original.benefits?.length === 1;

    if (parentObj && parentObj.children) {
      original.benefits.forEach((child) => {
        const childValue = parentObj.children.find((c) => c.value === child);

        if (childValue) {
          result.push([parent, child]);
          if (hasOnlyOneChild) {
            oneLabel = childValue.label;
          }
        }
      });
    }
  });

  return [result, oneLabel];
}

// 还原入参格式
export function restoreOriginalObject(pairs) {
  const originalObject = {
    category_value: [],
    benefits: []
  };

  pairs.forEach((pair) => {
    const [region, location] = pair;

    if (!originalObject.category_value.includes(region)) {
      originalObject.category_value.push(region);
    }

    originalObject.benefits.push(location);
  });

  return originalObject;
}

// 下拉框需要转换数据
// 将指定的key转换成children, 以及seo_value转value,value转label
// formatOptions(location_lists, ['locations'])
export const formatOptions = (arr: any[], keys: string[]): any[] => {
  return (arr ?? []).map((item) => {
    const result: any = {};

    Object.entries(item).forEach(([key, value]) => {
      if (keys.includes(key) && Array.isArray(value)) {
        result.children = formatOptions(value, keys);
      } else {
        result[key] = value;
      }
    });

    result.value = item["id"] || item["seo_value"];
    result.label = item.category_value || item.name;
    return result;
  });
};
