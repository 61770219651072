/* Components */
import React from "react";

import Link from "components/Link";
/* Helpers */
import { formatTemplateString, truncateWords } from "helpers/formatter";

// Styles
// import styles from "../Companies.module.scss";
import { CompanyDetail } from "../../typed";
import { isMobile } from "react-device-detect";
import { setCookie } from "helpers/cookies";
import { getDeviceUuid } from "helpers/guest";
import { useLanguage } from "app/components/providers/languageProvider";
import {
  getDeviceNameTracker,
  setCompanyViewCookiesTracker,
  viewCompanySourceTracker
} from "components/ReportTracker/util";

// import { Tooltip } from "antd";
import styles from "./index.module.scss";
import classNames from "classnames";

interface ICompanyCard {
  company: CompanyDetail;
  langKey: string;
}

const CompanyCard = (props: ICompanyCard) => {
  const { company, langKey } = props;

  const companyUrl = company?.company_url || "/";
  const { search } = useLanguage();

  const sendViewCompany = async () => {
    const device_udid = await getDeviceUuid();
    const params = {
      id: company?.id,
      payload: {
        source: viewCompanySourceTracker.companies,
        device: getDeviceNameTracker(isMobile),
        country_id: company?.country_id || "",
        device_udid,
        reco_from: company?.reco_from || null
      }
    };
    setCompanyViewCookiesTracker(setCookie, params);
  };

  const industryValue = company.company_tags; //?.join?.("&");
  const { is_default_description } = company as any;
  const getUrl = (company) => {
    return `/${langKey}${company.company_url}/jobs`;
  };

  return (
    <div
      className={isMobile ? "" : styles.companyCard}
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.open("/" + langKey + companyUrl);
        sendViewCompany();
      }}
      key={company?.id}
    >
      <div className={styles.topRoot}>
        <div className={styles.logoRow} style={{ height: 80 }}>
          <img
            alt={company?.company_name}
            src={company?.company_logo || company?.logo}
            className={styles.logo}
          />
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Link
              to={"/" + langKey + companyUrl}
              title={company?.company_name}
              target='_blank'
              onClick={sendViewCompany}
              className={styles.name}
            >
              {company.company_name}
            </Link>
            <div className={styles.industryWrapper}>
              {industryValue.map((v, i) => {
                return (
                  <React.Fragment key={v + i}>
                    {i !== 0 && <span key={v + i} className={styles.dot}></span>}
                    <span className={styles.industryItem}>{v}</span>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>

        <div className={classNames(styles.desc, is_default_description && styles.defaultDesc)}>
          {(company as any)?.description}
        </div>

        <div className={styles.tagRoot}>
          {((company as any)?.country_culture || []).filter(Boolean).map((v, i) => {
            return (
              <div className={styles.tagItem} key={i}>
                {v}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.bottomRoot}>
        <Link
          to={getUrl(company)}
          target='_blank'
          className={styles.jobOpeningTag}
          onClick={sendViewCompany}
        >
          <span className={styles.numberFlag}>{company.jobs_num}</span>
          {company.suffix_text}
        </Link>

        <span className={styles.viewMore}>{search.viewMore}</span>
      </div>
      {/* <Link
        to={getUrl(company)}
        target='_blank'
        className={styles.companyCardOpenings + " " + styles.mobileOpenings}
        onClick={sendViewCompany}
      >
        <p dangerouslySetInnerHTML={{ __html: viewJobString() }}></p>
      </Link> */}
    </div>
  );
};

export default CompanyCard;
