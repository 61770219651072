import { Drawer } from "antd/lib";

import styles from "./index.module.scss";
import { Collapse } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";

const CloseIcon = () => {
  //
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M18.3646 7.05025C18.7551 6.65972 18.7551 6.02656 18.3646 5.63603C17.974 5.24551 17.3409 5.24551 16.9504 5.63603L12.0006 10.5858L7.05086 5.63603C6.66033 5.24551 6.02717 5.24551 5.63664 5.63603C5.24612 6.02656 5.24612 6.65972 5.63664 7.05025L10.5864 12L5.63664 16.9497C5.24612 17.3403 5.24612 17.9734 5.63664 18.364C6.02717 18.7545 6.66033 18.7545 7.05086 18.364L12.0006 13.4142L16.9504 18.364C17.3409 18.7545 17.974 18.7545 18.3646 18.364C18.7551 17.9734 18.7551 17.3403 18.3646 16.9497L13.4148 12L18.3646 7.05025Z'
        fill='#4B4C4D'
      />
    </svg>
  );
};

const ArrowIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.85396 9.31136L3.53415 4.99155C3.2738 4.7312 2.85169 4.7312 2.59134 4.99155L2.44992 5.13297C2.18957 5.39332 2.18957 5.81543 2.44992 6.07578L7.85396 11.4798L13.258 6.07578C13.5184 5.81543 13.5184 5.39332 13.258 5.13297L13.1166 4.99155C12.8562 4.7312 12.4341 4.7312 12.1738 4.99155L7.85396 9.31136Z'
        fill='black'
      />
    </svg>
  );
};

const Arrow = () => {
  return (
    <span className={"arrowWrapper"}>
      <ArrowIcon />
    </span>
  );
};
function countTrueProperties(obj, visited = new Set()) {
  if (typeof obj !== "object" || obj === null) {
    return 0;
  }

  if (visited.has(obj)) {
    return 0;
  }
  visited.add(obj);

  let count = 0;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (value === true) {
        count++;
      } else if (value && typeof value === "object" && value !== null) {
        count += countTrueProperties(value, visited);
      }
    }
  }

  return count;
}

function extractTrueIds(data) {
  const result = {};

  function extractIds(obj, key) {
    if (!result[key]) {
      result[key] = [];
    }

    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        const value = obj[k];

        if (typeof value === "object" && value !== null) {
          extractIds(value, key);
        } else if (value === true && !isNaN(k as any)) {
          result[key].push(k);
        }
      }
    }
  }

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      extractIds(data[key], key);
    }
  }

  return result;
}
const convertUrlBenefits = (data, ids) => {
  const mapping = {};
  for (const item of data) {
    const parentSeoValue = item.seo_value;
    for (const benefit of item.benefits) {
      mapping[benefit.id] = parentSeoValue;
    }
  }

  const result = {};
  for (const id of ids) {
    const seoValue = mapping[id];
    if (seoValue) {
      if (!result[seoValue]) {
        result[seoValue] = {};
      }
      result[seoValue][id] = true;
    }
  }
  return result;
};

const convertUrlIndustry = (data, ids) => {
  const mapping = {};
  for (const item of data) {
    const parentSeoValue = item.value;
    for (const benefit of item.sub_filter) {
      mapping[benefit.id] = parentSeoValue;
    }
  }

  const result = {};
  for (const id of ids) {
    const seoValue = mapping[id];
    if (seoValue) {
      if (!result[seoValue]) {
        result[seoValue] = {};
      }
      result[seoValue][id] = true;
    }
  }
  return result;
};

const Label = ({
  text,
  type,
  number
}: {
  text: string;
  number?: number;
  type: "main" | "secondary";
}) => {
  return (
    <div
      className={classNames(
        styles.customerBaseHeader,
        type == "main" ? styles.customerMainHeader : styles.customerSecondaryHeader
      )}
    >
      {text}
      {Boolean(number) && <span className={styles.badge}>{number}</span>}
      <Arrow />
    </div>
  );
};
const MyCollapse = ({ label, children, defaultActiveKey = undefined }) => {
  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      destroyInactivePanel
      bordered={false}
      className={styles.collapseWrapper}
      items={[
        {
          showArrow: false,
          key: "1",
          label,
          children
        }
      ]}
    />
  );
};
export const SearchPanelH5 = ({ data, open, onClose, searchParams }) => {

  const {
    company_size_filter = [],
    financing_stage_filter = [],
    benefits_filter = [],
    industry_filter = [],
    benefits_text,
    industry_text,
    financing_stage_text,
    company_size_text
  } = data;

  const [form, setForm] = useState({
    industry: {},
    benefits: {},
    size: {},
    financing: {}
  });

  let filterNumber = useMemo(() => {
    let number = countTrueProperties(form);
    return number;
  }, [form]);

  useEffect(() => {
    const {
      benefits,
      company_size_ids = "",
      financing_stage_ids = "",
      industry_ids
    } = searchParams;
    let newForm = {
      industry: {},
      benefits: {},
      size: {},
      financing: {}
    };
    if (company_size_ids) {
      let newSize = {};
      company_size_ids.split(",").forEach((id) => {
        newSize[id] = true;
      });
      newForm.size = newSize;
    }
    if (financing_stage_ids) {
      let newFinancing = {};
      financing_stage_ids.split(",").forEach((id) => {
        newFinancing[id] = true;
      });
      newForm.financing = newFinancing;
    }
    if (benefits) {
      // benefits_filter
      newForm.benefits = convertUrlBenefits(data?.benefits_filter, benefits.split(","));
    }
    //
    if (industry_ids) {
      newForm.industry = convertUrlIndustry(data?.industry_filter, industry_ids.split(","));
    }
    setForm(newForm);
  }, [searchParams]);

  return (
    <Drawer height={"70vh"} open={open} placement='bottom' className={styles.searchPanelH5Root}>
      <div className={styles.customerContent}>
        <div className={styles.header}>
          <span className={styles.headerText}>{data?.filters_text}</span>
          <span
            className={styles.closeWrapper}
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </span>
        </div>

        {/* content */}
        <div className={styles.content}>
          <MyCollapse
            defaultActiveKey={"1"}
            label={
              <Label
                text={benefits_text}
                number={countTrueProperties(form.benefits || {})}
                type='main'
              />
            }
          >
            {benefits_filter.map((v) => {
              const seoValue = v.seo_value;
              const map = form.benefits[seoValue] || {};
              return (
                <MyCollapse
                  key={v.category_value}
                  label={<Label text={v.category_value} type='secondary' />}
                >
                  <div className={styles.tagWrapper}>
                    {v.benefits.map((v) => {
                      let hasChecked = Boolean(map[v.id]);
                      return (
                        <div
                          className={classNames(styles.tagItem, hasChecked && styles.checked)}
                          key={v.id}
                          onClick={(e) => {
                            let newBenefit = { ...(form.benefits?.[seoValue] || {}) };
                            newBenefit[v.id] = !hasChecked;

                            let newValue = {
                              ...form,
                              benefits: { ...form.benefits, [seoValue]: newBenefit }
                            };

                            setForm(newValue);
                          }}
                        >
                          {v.name}
                        </div>
                      );
                    })}
                  </div>
                </MyCollapse>
              );
            })}
          </MyCollapse>
          {/*  */}

          <MyCollapse
            label={
              <Label text={company_size_text} number={countTrueProperties(form.size)} type='main' />
            }
          >
            <div className={styles.tagWrapper} style={{ backgroundColor: "#fff" }}>
              {company_size_filter.map((v) => {
                let hasChecked = Boolean(form.size[v.id]);

                return (
                  <div
                    className={classNames(styles.tagItem, hasChecked && styles.checked)}
                    key={v.id}
                    onClick={() => {
                      let newSize = { ...(form.size || {}) };
                      newSize[v.id] = !hasChecked;

                      let newValue = {
                        ...form,
                        size: newSize
                      };

                      setForm(newValue);
                    }}
                  >
                    {v.value}
                  </div>
                );
              })}
            </div>
          </MyCollapse>

          <MyCollapse
            label={
              <Label
                text={financing_stage_text}
                number={countTrueProperties(form.financing)}
                type='main'
              />
            }
          >
            <div className={styles.tagWrapper} style={{ backgroundColor: "#fff" }}>
              {financing_stage_filter.map((v) => {
                let hasChecked = Boolean(form.financing[v.id]);

                return (
                  <div
                    className={classNames(styles.tagItem, hasChecked && styles.checked)}
                    // className={styles.tagItem}
                    key={v.id}
                    onClick={(e) => {
                      let newFinancing = { ...(form.financing || {}) };
                      newFinancing[v.id] = !hasChecked;

                      let newValue = {
                        ...form,
                        financing: newFinancing
                      };

                      setForm(newValue);
                    }}
                  >
                    {v.value}
                  </div>
                );
              })}
            </div>
          </MyCollapse>

          <MyCollapse
            label={
              <Label text={industry_text} number={countTrueProperties(form.industry)} type='main' />
            }
          >
            {industry_filter.map((v) => {
              const seoValue = v.value;
              const map = form.industry[seoValue] || {};

              return (
                <MyCollapse
                  key={v.value}
                  label={
                    <Label text={v.value} number={countTrueProperties(map)} type='secondary' />
                  }
                >
                  <div className={styles.tagWrapper}>
                    {v.sub_filter.map((v) => {
                      let hasChecked = Boolean(map[v.id]);

                      return (
                        <div
                          className={classNames(styles.tagItem, hasChecked && styles.checked)}
                          key={v.id}
                          onClick={(e) => {
                            let newIndustry = { ...(form.industry?.[seoValue] || {}) };
                            newIndustry[v.id] = !hasChecked;

                            let newValue = {
                              ...form,
                              industry: { ...form.industry, [seoValue]: newIndustry }
                            };

                            setForm(newValue);
                          }}
                        >
                          {v.value}
                        </div>
                      );
                    })}
                  </div>
                </MyCollapse>
              );
            })}
          </MyCollapse>
        </div>

        <div className={styles.footerRoot}>
          <span
            className={styles.cancelBtn}
            onClick={(e) => {
              onClose({});
            }}
          >
            Clear all
          </span>
          <span
            className={styles.submitBtn}
            onClick={(e) => {
              if (filterNumber) {
                let ids = extractTrueIds(form) as Record<string, any>;
                let params: Record<string, any> = {};

                if (ids.benefits?.length) {
                  params.benefits = ids.benefits;
                }
                if (ids.industry?.length) {
                  params.industry_ids = ids.industry;
                }
                if (ids.financing?.length) {
                  params.financing_stage_ids = ids.financing;
                }

                if (ids.size?.length) {
                  params.company_size_ids = ids.size;
                }

                onClose(params);
              } else {
                onClose({});
              }
            }}
          >
            {data?.apply_filter_text}
            {!filterNumber ? "" : `(${filterNumber})`}
          </span>
        </div>
      </div>
      {/*  */}
    </Drawer>
  );
};
