import classNames from "classnames";
import { HTMLProps } from "react";

import styles from "./index.module.scss";

export const Content = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(styles.contentWrapper, className)} {...rest} />;
};
