"use client";
import CompanyCard from "./CompanyCard/CompanyCard";
// import Empty from './Empty'
import Image from "next/image";
// Styles
import styles from "../Companies.module.scss";
import { getCookie } from "helpers/cookies";
import { useLanguage } from "app/components/providers/languageProvider";
import { postNotification } from "bossjob-remote/dist/clientStorage";
// import Empty from "./empty";
import NoDataImg from "../noData.png";

const NoDataSvg = () => {
  return <img src={NoDataImg.src} width={160} height={160} />;
};

interface ICompanyCardList {
  companiesList: any;
  isLoading?: boolean;
  empty?: any;
  langKey: string;
  page: number;
}

const CompanyCardList = (props: ICompanyCardList) => {
  const { companiesList, isLoading, langKey } = props;
  const _list = Boolean(companiesList?.length) ? companiesList : [];

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {!isLoading &&
        companiesList?.length > 0 &&
        _list.map((item) => {
          return <CompanyCard company={item} langKey={langKey} key={item.id} />;
        })}

      {!isLoading && !companiesList?.length && (
        // <Empty lang={empty} />
        <div
          className={styles.emptyNode}
          style={{
            padding: "100px 0"
          }}
        >
          <NoDataSvg />
          No Data
        </div>
      )}
    </div>
  );
};

export default CompanyCardList;
