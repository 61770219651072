import { SelectProps } from "antd";
import { formatOptions, generatePairs, restoreOriginalObject } from "./helper";
// import MultiSelect from "../../baseSelect/pc/MultiSelect";
import styles from "./index.module.scss";
import MultiSelect from "app/(new-pages)/components/allSelect/baseSelect/pc/MultiSelect";
type valueType = {
  category_value: string[];
  benefits: string[];
};

type PropsType = {
  label: string;
  benefits_filter: any[];
  value: valueType;
  searchBoardPlaceholder?: string;
  isRemote?: boolean;
  wrapperClassName?: string;
  realTimeRefresh?: boolean;
} & SelectProps;

export const BenefitsSelectPc = ({
  placeholder,
  value,
  onChange,
  benefits_filter,
  isRemote,
  ...rest
}: PropsType) => {
  const options = formatOptions(benefits_filter, ["benefits"]);
  const [newValue, oneLabel] = generatePairs(value, options);

  const generateChangeValue = (value) => {
    const changeValue = restoreOriginalObject(value);
    onChange(changeValue, options);
  };

  return (
    <div className={isRemote ? styles.benefits : styles.benefitsLocal}>
      <MultiSelect
        options={options}
        placeholder={placeholder}
        value={newValue}
        oneLabel={oneLabel as any}
        onChange={generateChangeValue}
        {...rest}
      />
    </div>
  );
};
