import classNames from "classnames";
import styles from "./index.module.scss";
const LeftIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.58579 12L8 10.5858L3.70711 6.29289L8 2L6.58579 0.585786L0.87868 6.29289L6.58579 12Z'
        fill='black'
      />
    </svg>
  );
};

export const PaginationForJp = ({ onChange, current, hasNext }) => {
  if (current === 1 && !hasNext) {
    return null;
  }

  return (
    <div className={styles.paginationRoot} style={{ marginTop: 20 }}>
      <div
        className={classNames(styles.paginationItem, current === 1 && styles.disabled)}
        onClick={(e) => {
          if (current <= 1) {
            return;
          }
          onChange(current - 1);
        }}
      >
        <LeftIcon />
      </div>

      <div
        className={classNames(styles.paginationItem, styles.rightBtn, !hasNext && styles.disabled)}
        onClick={(e) => {
          if (!hasNext) {
            return;
          }
          onChange(current + 1);
        }}
      >
        <LeftIcon />
      </div>
    </div>
  );
};
